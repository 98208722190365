<template>
  <div class="container">
    <div class="login">
      <div class="logo-row">
        <img src="@/assets/images/logo.png">
        <span>职堆堆后台</span>
      </div>
      <div class="flex justify-center">
        <div class="tab">账号密码登录</div>
      </div>
      <el-form>
        <el-form-item>
          <el-input v-model="form.account" placeholder="请输入账号">
            <i slot="prefix" class="el-icon-user"></i>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="form.pwd" :type="isShowPwd ? 'text' : 'password'" placeholder="请输入密码">
            <i slot="prefix" class="el-icon-lock"></i>
            <img v-if="isShowPwd" class="icon-eyes" slot="suffix" src="@/assets/images/pwd-show.png" @click="isShowPwd = !isShowPwd">
            <img v-else class="icon-eyes" slot="suffix" src="@/assets/images/pwd-hide.png" @click="isShowPwd = !isShowPwd">
          </el-input>
        </el-form-item>
        <!-- <el-form-item>
          <div class="flex items-center">
            <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
            <IdentifyCode class="ml16" :changeCode.sync='identifyCode'></IdentifyCode>
          </div>
        </el-form-item> -->
        <el-form-item>
          <SliderCheck ref="sliderCheckRef"></SliderCheck>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="form.remember" label="记住密码"></el-checkbox>
        </el-form-item>
        <el-button class="btn-login" type="primary" @click="handleLogin">登录</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import IdentifyCode from '@/components/IdentifyCode'
import SliderCheck from '@/components/SliderCheck'
import { loginApi } from '@/api/admin.js'
import { unifyLoginApi } from '@/api/opera.js'
import { getDeviceId } from '@/utils/util.js'
import md5 from 'js-md5'
export default {
  name: 'Login',
  components: {
    IdentifyCode,
    SliderCheck
  },
  data() {
    return {
      form: {
        account: '',
        pwd: '',
        code: '',
        remember: true,
      },
      identifyCode: '',
      isShowPwd: false
    }
  },
  created() {
    this.getCookie()
    document.addEventListener('keyup', this.handleEnterKeyup)
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEnterKeyup)
  },
  methods: {
    handleEnterKeyup(e) {
      if(e.keyCode == 13) {
        this.handleLogin()
      }
    },
    handleLogin() {
      if(!this.form.account.trim()) return this.$tips({message: '请输入账号', type: 'warning'})
      if(!this.form.pwd.trim()) return this.$tips({message: '请输入密码', type: 'warning'})
      if(!this.$refs.sliderCheckRef.confirmSuccess) return this.$tips({message: '请拖动滑块验证', type: 'warning'})
      if(this.form.remember) {
        this.setCookie(this.form.account.trim(), this.form.pwd.trim(), 7)
      } else {
        this.clearCookie()
      }
      const formData = {
        "account": this.form.account.trim(),
				"password": md5(this.form.pwd.trim()),
      }
      console.log('formData', formData)
      unifyLoginApi(formData).then(res => {
        console.log('登录成功',res)
        this.$store.commit('LOGIN', {
          token: res.data.loginToken,
          userId: res.data.userId,
          authCodes: res.data.functionCodes,
          authChs: res.data.functionChs
        })
        this.$router.push('/')
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    //设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie = "userName" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie = "userPwd" + "=" + c_pwd + ";path=/;expires=" + exdate.toGMTString();
    },
    //读取cookie
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('='); //再次切割
          //判断查找相对应的值
          if (arr2[0] == 'userName') {
            this.form.account = arr2[1]; //保存到保存数据的地方
          } else if (arr2[0] == 'userPwd') {
            this.form.pwd = arr2[1];
          }
        }
      }
    },
    //清除cookie
    clearCookie() {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('~@/assets/images/bg.svg');
}
.login {
  width: 450px;
  .logo-row {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 33px;
    font-weight: 500;
    img {
      width: 44px;
      height: 44px;
      margin-right: 30px;
    }
  }
  .tab {
    height: 40px;
    line-height: 40px;
    padding: 0 8px;
    margin: 40px 0 20px;
    border-bottom: 2px solid var(--theme-color);
    color: var(--theme-color);
  }
  .btn-login {
    width: 100%;
  }
  .icon-eyes {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}
</style>